import React, { useState, useEffect } from "react";
import GetApi from "../Api/GetApi";
import { ServerLink } from "../inc/keys/Links";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const SearchCourse = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllNotes();
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  }, []);

  const getAllNotes = (e) => {
    setLoading(true);
    GetApi(ServerLink + "/all-notes")
      .then((res) => {
        if (res) {
          setAllNotes(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error. Try again",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
      });
    setLoading(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filteredSuggestions = allNotes.filter((item) =>
      item.topic.toLowerCase().includes(value.toLowerCase())
    );

    // const filteredSuggestions = allNotes.filter(({ topic, id, type }) =>
    //   topic.toLowerCase().includes(value.toLowerCase())
    // );

    setSuggestions(filteredSuggestions);
  };

  return (
    <div>
      <div className="">
        {searchTerm === "" ? (
          <div>
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <i className="fa fa-search pr-2 text-gray-500"></i>
            </div>
          </div>
        ) : null}
        <div>
          <input
            type="text"
            className={
              searchTerm === ""
                ? "border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[93vw] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 py-3 md:py-3"
                : "border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[93vw] pl-4 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 py-3 md:py-3"
            }
            value={searchTerm}
            onChange={handleSearch}
            placeholder={loading ? "Loading..." : "Search for a course"}
            required
          />
        </div>
      </div>
      {/* <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search..."
      /> */}
      <div>
        {searchTerm !== "" && suggestions.length > 0 && (
          <ul>
            {suggestions.map((item) => (
              <div>
                <Link
                  to={
                    item.type === "disease"
                      ? "/disease/" + item.id
                      : "/note/" + item.id
                  }
                >
                  <li className="pl-4" key={item.id}>
                    {item.topic}
                  </li>
                  <hr className="py-2" />
                </Link>
              </div>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchCourse;
