import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getToken } from "./Common";

function AuthRoute() {
  const auth = getToken();
  return auth ? <Outlet /> : <Navigate to="/signin" />;
}

export default AuthRoute;
