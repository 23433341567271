import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import userProfile from "../../../assets/images/icons/user.png";
import GetApi from "../../Api/GetApi";
import PostApi from "../../Api/PostApi";
import { postApiMultipart } from "../../Api/PostApi";
import { ServerLink, FileLink } from "../../inc/keys/Links";
import Swal from "sweetalert2";
import Spinner from "../../inc/Spinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    setLoading(true);
    GetApi(ServerLink + "/auth/me")
      .then((res) => {
        if (res) {
          setFirstName(res.firstname);
          setLastName(res.lastname);
          setEmail(res.email);
          setPicture(res.profile_picture);
          setLoading(false);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const update = (e) => {
    e.preventDefault();
    setUpdating(true);
    const data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
    };
    PostApi(ServerLink + "/update-user", data)
      .then((res) => {
        if (res.status === true) {
          Swal.fire({
            text: res.message,
            icon: "success",
            confirmButtonColor: "#1437E9",
          });
          setUpdating(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setUpdating(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setUpdating(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      Swal.fire({
        text: "The image must not be greater than 5MB. Kindly choose another image",
        icon: "error",
        confirmButtonColor: "#1437E9",
      });
      setOpen(false);
    } else {
      const formData = new FormData();
      formData.append("picture", e.target.files[0]);
      updatePicture(formData);
    }
  };

  const updatePicture = (data) => {
    setLoading(true);
    postApiMultipart(ServerLink + "/update-picture", data)
      .then((res) => {
        if (res.status === true) {
          setLoading(false);
          Swal.fire({
            text: res.message,
            icon: "success",
            confirmButtonColor: "#1437E9",
          });
          navigate("/profile");
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setOpen(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error. Try again",
          icon: "success",
          confirmButtonColor: "#1437E9",
        });
        setOpen(false);
      });
  };

  return (
    <div className="">
      <div
        className="bg-gray-500 m-0 p-0 h-screen"
        style={{ background: "#F9FAFF", overflow: "hidden" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="grid grid-cols-6 items-center justify-between bg-white fixed w-screen px-2 py-2">
              <Link to="/profile">
                <div className="col-span-1 pl-4 py-1">
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </div>
              </Link>
              <div className="col-span-4 text-center bluetext text-lg py-1">
                <b>Edit Profile</b>
              </div>
              <div className="col-span-1 pl- text-center py-1"></div>
            </div>
            <div className="flex justify-around items-center h-50 w-50 rounded-full mr-2 pt-16 text-center">
              {picture !== null ? (
                <img
                  className="img-big-circle mx-auto"
                  src={FileLink + "/picturex/" + picture}
                  alt="profile"
                  width="100%"
                  style={{ border: "3px var(--primary-color) solid" }}
                />
              ) : (
                <img
                  className="img-big-circle mx-auto"
                  src={userProfile}
                  alt="profile"
                />
              )}
            </div>
            <div
              className="text-center mt-2 pb-3"
              onClick={() => setOpen(true)}
            >
              <p className="bluetext underline">Edit</p>
              {open && (
                <div className="flex justify-center pl-20 pt-4">
                  <input
                    type="file"
                    className="text-center"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <form action="" className="px-2">
              <div className="mt-5">
                <div className="">
                  <p className="text-sm text-left pb-1">First Name</p>
                  <div className="relative mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H4v-2c0-2.66 5.33-4 8-4z" />

                        {/* <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path> */}
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[22rem] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                      defaultValue={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <p className="text-sm text-left pb-1">Last Name</p>
                  <div className="relative mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H4v-2c0-2.66 5.33-4 8-4z" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[22rem] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                      defaultValue={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <p className="text-sm text-left pb-1">Email</p>
                  <div className="relative mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                      </svg>
                    </div>
                    <input
                      type="email"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[22rem] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                      disabled
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="mb-4">
                    {updating ? (
                      <button
                        type="button"
                        className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-[22rem]"
                        data-te-ripple-init
                        disabled
                      >
                        Updating...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="bg-primary text-white px-7 py-3 rounded-lg w-[22rem]"
                        data-te-ripple-init
                        onClick={update}
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(EditProfile);
