import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ServerLink } from "../../inc/keys/Links";
import Swal from "sweetalert2";
import PostApi from "../../Api/PostApi";
import Spinner from "../../inc/Spinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [nPassword, setNPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  const update = (e) => {
    e.preventDefault();
    setUpdating(true);
    const data = {
      password: password,
      new_password: nPassword,
      confirm_password: cPassword,
    };

    PostApi(ServerLink + "/update-password", data)
      .then((res) => {
        if (res.status === true) {
          Swal.fire({
            text: res.message,
            icon: "success",
            confirmButtonColor: "#1437E9",
          });
          setUpdating(false);
          setTimeout(() => {
            navigate("/profile");
          }, 1000);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setUpdating(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setUpdating(false);
      });
  };

  return (
    <div className="">
      <div
        className="bg-gray-500 m-0 p-0 h-screen"
        style={{ background: "#F9FAFF", overflow: "hidden" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="grid grid-cols-6 items-center justify-between bg-white fixed w-screen px-2 py-2">
              <Link to="/security">
                <div className="col-span-1 pl-4 py-1">
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </div>
              </Link>
              <div className="col-span-4 text-center bluetext text-lg py-1">
                <b>Change My Password</b>
              </div>
              <div className="col-span-1 pl- text-center py-1"></div>
            </div>
            <form action="" className="px-4">
              <div className="pt-6">
                <div className="">
                  <p className="text-sm text-left pb-1 mt-10">
                    Enter Current Password
                  </p>
                  <div className="relative mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                      </svg>
                    </div>
                    <input
                      type="password"
                      className="border border-gray-300 text-gray-900 text rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[22rem] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                      defaultValue={password}
                      placeholder="Enter current password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <p className="text-sm text-left pb-1">Enter New Password</p>
                  <div className="relative mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                      </svg>
                    </div>
                    <input
                      type="password"
                      className="border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[22rem] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                      defaultValue={nPassword}
                      placeholder="Enter new password"
                      onChange={(e) => setNPassword(e.target.value)}
                      required
                    />
                  </div>
                  <p className="text-sm text-left pb-1">Confirm New Password</p>
                  <div className="relative mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                      </svg>
                    </div>
                    <input
                      type="password"
                      className="border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[22rem] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                      defaultValue={cPassword}
                      placeholder="Confirm new password"
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="mb-4">
                    {updating ? (
                      <button
                        type="button"
                        className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-[22rem]"
                        data-te-ripple-init
                        disabled
                      >
                        Updating...
                      </button>
                    ) : password === "" ||
                      nPassword === "" ||
                      cPassword === "" ? (
                      <button
                        type="button"
                        className="bg-gray-400 text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-[22rem]"
                        data-te-ripple-init
                        disabled
                      >
                        Fill all fields
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="bg-primary text-white px-7 py-3 rounded-lg w-[22rem]"
                        data-te-ripple-init
                        onClick={update}
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ChangePassword);
