import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import logoicon from "../../assets/images/logos/logoicon.png";
import fullLogo from "../../assets/images/logos/full-logo.png";
import { ServerLink } from "../inc/keys/Links";
import PostApi from "../Api/PostApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [hear, setHear] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setError(false);
    setErrorMessage("");
    // setLoading(false);
  }, []);

  const Register = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      firstname: firstName.trim(),
      lastname: lastName.trim(),
      email: email.trim(),
      password: password,
      confirm_password: cPassword,
      know_about_us: hear,
    };

    PostApi(ServerLink + "/auth/register", data)
      .then((res) => {
        if (res.status === true) {
          Swal.fire({
            icon: "Success",
            text: res.message,
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/verify-email");
          }, 1000);
        } else {
          Swal.fire({
            text: res.message.email
              ? res.message.email[0]
              : res.message.password
              ? res.message.password[0]
              : res.message.confirm_password
              ? res.message.confirm_password[0]
              : "",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "Success",
          text: "Network error. Try again",
          confirmButtonColor: "#1437E9",
        });
      });
  };

  return (
    <div>
      <div className="hidden md:grid grid-cols-6 gap-4 items-center justify-center text-center bg-primary h-screen">
        <div className="col-start-2 col-span-4">
          <Card className="overflow-hidden">
            <form onSubmit={Register}>
              <CardBody className="text-center">
                <img
                  className="mx-auto pb-4"
                  src={fullLogo}
                  alt="logo"
                  width="150px"
                />
                <h2 className="header-font text-3xl bluetext">
                  Get Started with DigiBridge
                </h2>
                <div className="px-7 pb-4">
                  <p>
                    Join thousands of nursing students on this journey to
                    distinction.
                  </p>
                </div>
                <div>
                  <div className="">
                    <div className="flex items-center justify-center">
                      <div className="">
                        <p className="text-sm text-left pb-1">First Name</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H4v-2c0-2.66 5.33-4 8-4z" />
                            </svg>
                          </div>
                          <input
                            type="name"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">Last Name</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H4v-2c0-2.66 5.33-4 8-4z" />
                            </svg>
                          </div>
                          <input
                            type="name"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">Email</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                          </div>
                          <input
                            type="email"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">Password</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                            </svg>
                          </div>
                          <input
                            type="password"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">
                          Confirm Password
                        </p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                            </svg>
                          </div>
                          <input
                            type="password"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setCPassword(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">
                          How did you know about us?
                        </p>
                        <div className="relative mb-4">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                          </div>
                          <select
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3 pr-6"
                            required
                            name="hear"
                            value={hear}
                            onChange={(e) => setHear(e.target.value)}
                          >
                            <option value="">Select an option</option>
                            <option value="Twitter">Twitter</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Google">Google</option>
                          </select>
                          {/* <input
                            type="email"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-96 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          /> */}
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter className="pt-2">
                <div className=" mb-4 -ml-5">
                  {loading ? (
                    <button
                      type="button"
                      className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-96"
                      data-te-ripple-init
                      disabled
                    >
                      Please wait...
                    </button>
                  ) : firstName === "" ||
                    lastName === "" ||
                    email === "" ||
                    password === "" ||
                    cPassword === "" ||
                    hear === "" ? (
                    <button
                      type="button"
                      className="bg-gray-400 text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-96"
                      data-te-ripple-init
                      disabled
                    >
                      Kindly fill all the fields
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 w-96"
                      data-te-ripple-init
                      // onClick={subscribe}
                    >
                      Register for Free
                    </button>
                  )}
                </div>
                <p className="text-sm">
                  Do you have an account?{" "}
                  <Link to="/signin">
                    <span className="bluetext text-sm">
                      <b>Log In</b>
                    </span>
                  </Link>
                </p>
              </CardFooter>
            </form>
          </Card>
        </div>
      </div>
      <div className="md:hidden grid grid-cols-8 items-center justify-center text-center h-screen">
        <div className="col-span-8">
          <div className="">
            <form onSubmit={Register}>
              <div className="text-center">
                <img
                  className="mx-auto mt-4 mb-4"
                  src={logoicon}
                  alt="logo"
                  width="50px"
                />
                <h2 className="header-font text-3xl bluetext">
                  Get Started with <br /> DigiBridge
                </h2>
                <div className="mt-2 pb-4">
                  <p>
                    Join thousands of nursing students on this journey to
                    distinction.
                  </p>
                </div>
                <div className="">
                  <div className="pl-5">
                    <div className="flex items-center justify-center">
                      <div className="">
                        <p className="text-sm text-left pb-1">First Name</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H4v-2c0-2.66 5.33-4 8-4z" />

                              {/* <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path> */}
                            </svg>
                          </div>
                          <input
                            type="text"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-80 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your first name"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">Last Name</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H4v-2c0-2.66 5.33-4 8-4z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-80 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your last name"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">Email</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                          </div>
                          <input
                            type="email"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-80 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">Password</p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                            </svg>
                          </div>
                          <input
                            type="password"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-80 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Input your password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">
                          Confirm Password
                        </p>
                        <div className="relative mb-3">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M12,4.5c-2.48,0-4.5,2.02-4.5,4.5v2H6v9h12v-9h-1.5v-2c0-2.48-2.02-4.5-4.5-4.5zm-3,6c-.83,0-1.5-.67-1.5-1.5S8.17,7.5,9,7.5s1.5.67,1.5,1.5S9.83,10.5,9,10.5zm3,5.25c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25z" />
                            </svg>
                          </div>
                          <input
                            type="password"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-80 pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                            placeholder="Conform your password"
                            onChange={(e) => setCPassword(e.target.value)}
                            required
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="text-sm text-left pb-1">
                          How did you know about us?
                        </p>
                        <div className="relative mb-4">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                          </div>
                          <Select
                            className="w-80 text-left rounded-lg"
                            defaultValue={hear}
                            onChange={setHear}
                            options={[
                              { value: "Twitter", label: "Twitter" },
                              { value: "Facebook", label: "Facebook" },
                              { value: "Instagram", label: "Instagram" },
                              { value: "Google", label: "Google" },
                            ]}
                          />
                          {error ? (
                            <p className="text-danger">{errorMessage}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div className=" mb-4">
                  {loading ? (
                    <button
                      type="button"
                      className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-80"
                      data-te-ripple-init
                      disabled
                    >
                      Please wait...
                    </button>
                  ) : firstName === "" ||
                    lastName === "" ||
                    email === "" ||
                    password === "" ||
                    cPassword === "" ||
                    hear === "" ? (
                    <button
                      type="button"
                      className="bg-gray-400 text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-80"
                      data-te-ripple-init
                      disabled
                    >
                      Kindly fill all fields
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-primary text-white px-7 py-3 rounded-lg w-80"
                      data-te-ripple-init
                      // onClick={subscribe}
                    >
                      Register for Free
                    </button>
                  )}
                </div>
                <p className="text-sm pb-2">
                  Do you have an account?{" "}
                  <Link to="/signin">
                    <span className="bluetext text-sm">
                      <b>Log In</b>
                    </span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
