import cardiovascular from "../../assets/images/systems/cardiovascular.png";
import endocrine from "../../assets/images/systems/endocrine.png";
import digestive from "../../assets/images/systems/digestive.png";
import immune from "../../assets/images/systems/immune.png";
import senses from "../../assets/images/systems/senses.png";
import lymphatic from "../../assets/images/systems/lymphatic.png";
import skeletal from "../../assets/images/systems/skeletal.png";
import nervous from "../../assets/images/systems/nervous.png";
import reproductive from "../../assets/images/systems/reproductive.png";
import respiratory from "../../assets/images/systems/respiratory.png";
import renal from "../../assets/images/systems/renal.png";
export const SystemImages = [
  { icon: cardiovascular, id: 1 },
  { icon: endocrine, id: 2 },
  { icon: digestive, id: 3 },
  { icon: immune, id: 4 },
  { icon: senses, id: 5 },
  { icon: lymphatic, id: 6 },
  { icon: skeletal, id: 7 },
  { icon: nervous, id: 8 },
  { icon: reproductive, id: 9 },
  { icon: respiratory, id: 10 },
  { icon: renal, id: 11 },
];
