import React, { useState, useEffect } from "react";
import Spinner from "../../inc/Spinner";
import { Link } from "react-router-dom";
import GetApi from "../../Api/GetApi";
import { ServerLink } from "../../inc/keys/Links";
import Swal from "sweetalert2";
import score from "../../../assets/images/icons/score.png";
import heart from "../../../assets/images/heart-img.png";

const Quiz = ({ questions }) => {
  const [q, setQ] = useState(0);
  const [finalScore, setFinalScore] = useState(0);
  const [percent, setPercent] = useState(0);
  const [res, setRes] = useState(null);
  const [responses, setResponses] = useState([]);
  const [screen, setScreen] = useState("quiz");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  let j = 1;
  let k = 0;

  const getUser = () => {
    setLoading(true);
    GetApi(ServerLink + "/auth/me")
      .then((res) => {
        if (res) {
          setUser(res);
          setLoading(false);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        console.log(e);
      });
  };

  // const responses = [];
  const next = () => {
    const response = responses.find((obj) => obj.id === questions[q].id);
    if (response) {
      const nextResponse = responses.find(
        (obj) => obj.id === questions[q + 1].id
      );
      response.ans = res;
      if (nextResponse) {
        setRes(nextResponse.ans);
      }
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setQ(q + 1);
      }, 200);
    } else {
      setResponses((responses) => [
        ...responses,
        { id: questions[q].id, ans: res },
      ]);
      setRes(null);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setQ(q + 1);
      }, 200);
    }
  };

  const previous = () => {
    setRes(responses[q - 1].ans);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setQ(q - 1);
    }, 200);
  };

  const finish = () => {
    const response = responses.find((obj) => obj.id === questions[q].id);
    if (response) {
      response.ans = res;
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setQ(0);
        setScreen("finish");
      }, 1000);
    } else {
      setResponses((responses) => [
        ...responses,
        { id: questions[q].id, ans: res },
      ]);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setQ(0);
        setScreen("finish");
      }, 1000);
    }
  };

  const review = () => {
    console.log(responses);
    const firstResponse = responses.find((obj) => obj.id === questions[0].id);
    if (firstResponse) {
      setRes(firstResponse.ans);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setScreen("quiz");
    }, 200);
  };

  const submit = () => {
    let score = 0;
    for (let i = 0; i < responses.length; i++) {
      for (let i = 0; i < questions.length; i++) {
        if (responses[i].id === questions[i].id) {
          if (responses[i].ans === questions[i].answer) {
            score += 1;
            responses[i]["verdict"] = "PASS";
          } else {
            responses[i]["verdict"] = "FAIL";
          }
        }
      }
      break;
    }
    const percentage = ((score / questions.length) * 100).toFixed(2);
    setFinalScore(score);
    setPercent(percentage);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setScreen("score");
      console.log(responses);
    }, 1000);
  };

  const retake = () => {
    setQ(0);
    setFinalScore(0);
    setPercent(0);
    setRes(null);
    setResponses([]);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setScreen("quiz");
    }, 1000);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mx-5 pt-24 bg-white">
          {screen === "quiz" ? (
            <div className="">
              <div className="text-center mb-4">
                <p className="bluetext text-xs mb-2">
                  Question {q + 1} of {questions.length}
                </p>
                <b className="">{questions[q].question}</b>
              </div>
              <div>
                <div className="question-wrap">
                  <label
                    class="containerr"
                    onClick={() => {
                      setRes("option_a");
                    }}
                  >
                    {questions[q].option_a}
                    {res === "option_a" ? (
                      <input type="radio" name="question" defaultChecked />
                    ) : (
                      <input type="radio" name="question" />
                    )}
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div className="question-wrap">
                  <label
                    class="containerr"
                    onClick={() => {
                      setRes("option_b");
                    }}
                  >
                    {questions[q].option_b}
                    {res === "option_b" ? (
                      <input type="radio" name="question" defaultChecked />
                    ) : (
                      <input type="radio" name="question" />
                    )}
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div className="question-wrap">
                  <label
                    class="containerr"
                    onClick={() => {
                      setRes("option_c");
                    }}
                  >
                    {questions[q].option_c}
                    {res === "option_c" ? (
                      <input type="radio" name="question" defaultChecked />
                    ) : (
                      <input type="radio" name="question" />
                    )}
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div className="question-wrap">
                  <label
                    class="containerr"
                    onClick={() => {
                      setRes("option_d");
                    }}
                  >
                    {questions[q].option_d}
                    {res === "option_d" ? (
                      <input type="radio" name="question" defaultChecked />
                    ) : (
                      <input type="radio" name="question" />
                    )}
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-1">
                {q === 0 ? (
                  <button
                    type="submit"
                    className="bg-primary col-span-2 text-white px-4 py-2 rounded-lg w-full"
                    data-te-ripple-init
                    onClick={next}
                  >
                    Next Question
                  </button>
                ) : q + 1 === questions.length ? (
                  <button
                    type="submit"
                    className="bg-primary col-span-2 text-white px-4 py-2 rounded-lg w-full"
                    data-te-ripple-init
                    onClick={finish}
                  >
                    Finish Quiz
                  </button>
                ) : (
                  <div className="grid grid-cols-2 gap-4">
                    <button
                      type="submit"
                      className="bg-primary text-white px-4 py-2 rounded-lg w-full"
                      data-te-ripple-init
                      // disabled
                      onClick={previous}
                    >
                      Prev Question
                    </button>
                    <button
                      type="submit"
                      className="bg-primary text-white px-4 py-2 rounded-lg w-full"
                      data-te-ripple-init
                      onClick={next}
                    >
                      Next Question
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : screen === "finish" ? (
            <div className="bg-white">
              <p className="mb-4">
                You have completed this quiz. Click the submit button to see
                your scores
              </p>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  className="btn-sec px-4 py-2 rounded-lg w-ful cursor-not-allowed w-80l"
                  data-te-ripple-init
                  // disabled
                  onClick={review}
                >
                  Review
                </button>
                <button
                  type="button"
                  className="bg-primary text-white px-4 py-2 rounded-lg w-full"
                  data-te-ripple-init
                  onClick={submit}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : screen === "score" ? (
            <div className="mb-4">
              <div className="text-center ">
                <p className="text-xs mb-2">Quiz Result</p>
                <b className="text-2xl bluetext">
                  You got {finalScore} out of {questions.length} questions (
                  {percent}% )
                </b>
              </div>
              <div className="mt-5 p-4" style={{ background: "#F9FAFF" }}>
                <img
                  src={score}
                  alt="good-job"
                  className="mx-auto p-4"
                  style={{ background: "#E8FFF2" }}
                />
                <p className="text-center bluetext my-2">
                  GREAT ATTEMPT TODAY!
                </p>
                <p className="text-center">
                  This was a great attempt today. You can always do better with
                  constant revision, {user.firstname}
                </p>
              </div>
              <div className="grid grid-cols-1">
                <button
                  type="submit"
                  className="bg-primary text-white px-4 py-2 mt-4 rounded-lg w-full"
                  data-te-ripple-init
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                      setScreen("answers");
                    }, 1000);
                  }}
                >
                  View Answers
                </button>
                <Link to="/dashboard">
                  <button
                    type="submit"
                    className="btn-sec px-4 py-2 mt-3 rounded-lg w-full"
                    data-te-ripple-init
                    onClick={next}
                  >
                    Return Home
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              {questions.map((question) => (
                <div className="mb-5">
                  <div className="grid grid-cols-2">
                    <p className="text-sm bluetext">
                      Question {j++} of {questions.length}
                    </p>

                    {/* {console.log("response", responses[k++])} */}
                    {responses[k].id === question.id ? (
                      responses[k].verdict === "PASS" ? (
                        <p
                          className="text-sm flex justify-end"
                          style={{ color: "green" }}
                        >
                          {responses[k].verdict}
                        </p>
                      ) : (
                        <p
                          className="text-sm flex justify-end text-error"
                          style={{ color: "red" }}
                        >
                          {responses[k].verdict}
                        </p>
                      )
                    ) : null}
                  </div>

                  <div hidden>{k++}</div>
                  <img src={heart} alt="question" className="mx-auto py-2" />
                  <p className="">{question.question}</p>
                  {question.answer === "option_a" ? (
                    <b className="text-sm bluetext leading-3">
                      ANSWER: {question.option_a}
                    </b>
                  ) : question.answer === "option_b" ? (
                    <b className="text-sm bluetext">
                      ANSWER: {question.option_b}
                    </b>
                  ) : question.answer === "option_c" ? (
                    <b className="text-sm bluetext leading-3">
                      ANSWER: {question.option_c}
                    </b>
                  ) : question.answer === "option_d" ? (
                    <b className="text-sm bluetext">
                      ANSWER: {question.option_d}
                    </b>
                  ) : null}
                </div>
              ))}
              <div className="grid grid-cols-1">
                <button
                  type="submit"
                  className="bg-primary text-white px-4 py-2 mt-2 rounded-lg w-full"
                  data-te-ripple-init
                  onClick={retake}
                >
                  Retake Quiz
                </button>
                <Link to="/dashboard">
                  <button
                    type="submit"
                    className="btn-sec px-4 py-2 mt-3 mb-10 rounded-lg w-full"
                    data-te-ripple-init
                    onClick={next}
                  >
                    Return Home
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Quiz;
