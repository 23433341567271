import { SET_AUTH_DATA } from "../actions/authActions";

const initialState = {
  token: "",
  user: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_DATA:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    default:
      return state;
  }
}

export default authReducer;
