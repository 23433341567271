import mental from "../../assets/images/icons/mental.png";
import pharmac from "../../assets/images/icons/pharmac.png";
import fon from "../../assets/images/icons/fon.png";
import ethics from "../../assets/images/icons/mgt.png";
import phc from "../../assets/images/icons/phc.png";
export const icons = [
  { icon: mental, id: 2 },
  { icon: pharmac, id: 3 },
  { icon: fon, id: 4 },
  { icon: ethics, id: 5 },
  { icon: phc, id: 6 },
];
