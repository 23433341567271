import cookie from "js-cookie";

let token = cookie.get("token");

async function GetApi(url = "") {
  token = cookie.get("token");
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
}

export default GetApi;
