import React, { useEffect, useState } from "react";
import Structure from "../Layout/Structure";
import { connect } from "react-redux";
import medsurg from "../../../assets/images/icons/medsurg.png";
import { Link } from "react-router-dom";
import GetApi from "../../Api/GetApi";
import { ServerLink } from "../../inc/keys/Links";
import Swal from "sweetalert2";
import Spinner from "../../inc/Spinner";
import { icons } from "../../inc/Icons";

const QuizIndex = () => {
  const [user, setUser] = useState({});
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("available");

  useEffect(() => {
    getUser();
    getCourses();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getUser = () => {
    setLoading(true);
    GetApi(ServerLink + "/auth/me")
      .then((res) => {
        if (res) {
          setUser(res);
          setLoading(false);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error. Try again",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
      });
  };

  const getCourses = () => {
    setLoading(true);
    GetApi(ServerLink + "/courses")
      .then((res) => {
        if (res.status === true) {
          setCourses(res.data);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const page = (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="md:mt-24 mt-2">
          <h1 className="">Hey, {user.firstname}</h1>
          <p className="bluetext">
            <b>Ready to test yourself today?</b>
          </p>
          <div className="md:hidden relative my-4">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <i className="fa fa-search pr-2 text-gray-500"></i>
            </div>
            <input
              type="email"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-[93vw] pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 py-3 md:py-3"
              placeholder="Search for a quiz"
              required
            />
          </div>
          <div className="grid grid-cols-6 items-center justify-between">
            <div
              className={
                currentPage === "available"
                  ? "col-span-3 text-center py-2 text-sm bluetext text-bold bg-white"
                  : "col-span-3 text-center py-2 text-sm"
              }
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setCurrentPage("available")}
            >
              Available
            </div>
            <div
              className={
                currentPage === "completed"
                  ? "col-span-3 text-center py-2 text-sm bluetext text-bold bg-white"
                  : "col-span-3 text-center py-2 text-sm"
              }
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setCurrentPage("completed")}
            >
              Completed
            </div>
          </div>

          {currentPage === "available" ? (
            <div>
              <div className="-mx-3 px-3">
                <div className="bg-white grid md:hidden grid-cols-2 gap rounded-lg px-2">
                  <div className="flex col-span-2 items-center justify-between py-2">
                    <div className="flex items-center">
                      <img src={medsurg} alt="course-icon" className="pr-2" />
                      <div>
                        <p className="pb-0 mb-0">
                          <strong>MedSurg Nursing</strong>
                        </p>
                        <p className="text-sm" style={{ color: "gray" }}>
                          11 Systems Available
                        </p>
                      </div>
                    </div>
                    <Link to="/systems">
                      <i
                        class="fa fa-chevron-circle-right pr-2"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>

              <div>
                {courses.map(({ id, name, total }) => (
                  <div className="-mx-3 px-3 mt-2">
                    <div className="bg-white grid md:hidden grid-cols-2 gap rounded-lg px-2">
                      <div className="flex col-span-2 items-center justify-between py-2">
                        <div className="flex items-center">
                          {icons.map((icon) =>
                            icon.id === id ? (
                              <img
                                src={icon.icon}
                                alt="course-icon"
                                className="pr-2"
                              />
                            ) : null
                          )}
                          <div>
                            <p className="pb-0 mb-0">
                              <strong>{name}</strong>
                            </p>
                            <p className="text-sm" style={{ color: "gray" }}>
                              {total} Notes Available
                            </p>
                          </div>
                        </div>
                        <Link to={"/course/" + id}>
                          <i
                            class="fa fa-chevron-circle-right pr-2"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center mt-24">Coming soon</div>
          )}
          <div className="mb-24 pb-24"></div>
        </div>
      )}
    </>
  );

  return (
    <div>
      <Structure page={page} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(QuizIndex);
// export default Home;
