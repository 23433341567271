import React from "react";
import welcome from "../../assets/images/onboarding/welcome.png";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import confetti from "../../assets/images/logos/confetti.png";

const Welcome = () => {
  return (
    <div>
      <div className="hidden md:grid grid-cols-6 gap-4 items-center justify-center text-center bg-primary h-screen">
        <div className="col-start-2 col-span-4">
          <Card className="overflow-hidden">
            <CardBody className="text-center">
              <img
                className="mx-auto pb-4"
                src={confetti}
                alt="logo"
                width="150px"
              />
              <h2 className="header-font text-3xl bluetext">
                Hurray! It is great to have you join Digi Bridge
              </h2>
              <div className="px-7">
                <p>
                  You now have access to over 1,000+ education materials for
                  your nursing career.
                </p>
              </div>
              <div className="p-4">
                <img className="mx-auto" src={welcome} alt="welcome" />
                <hr />
              </div>
            </CardBody>
            <CardFooter className="flex justify-center gap-7 pt-2">
              <Link to="/dashboard">
                <button
                  type="button"
                  className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2.5 w-96"
                  data-te-ripple-init
                >
                  Proceed to Home
                </button>
              </Link>
            </CardFooter>
          </Card>
        </div>
      </div>
      <div className="md:hidden grid grid-cols-12 gap-4 items-center justify-center text-center h-screen">
        <div className="col-start-2 col-span-10">
          <div className="overflow-hidden">
            <div className="text-center">
              <img
                className="mx-auto mt-4 mb-4"
                src={confetti}
                alt="logo"
                width="50px"
              />
              <h2 className="header-font text-3xl bluetext">
                Hurray! It is great to have you join Digi Bridge
              </h2>
              <div className="mt-2">
                <p>
                  You now have access to over 1,000+ education materials for
                  your nursing career.
                </p>
              </div>
              <div className="">
                <img className="mx-auto" src={welcome} alt="welcome" />
                <hr />
              </div>
            </div>
            <div className="flex justify-center mb-2">
              <Link to="/dashboard">
                <button
                  type="button"
                  className="bg-primary text-white px-7 py-3 rounded-lg mt-4 w-96"
                  data-te-ripple-init
                >
                  Proceed to Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
