import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ServerLink } from "../../inc/keys/Links";
import GetApi from "../../Api/GetApi";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../inc/Spinner";

const CourseView = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getNotes();
    //eslint-disable-next-line
  }, []);

  const getNotes = () => {
    setLoading(true);
    GetApi(ServerLink + "/course/" + id + "/notes")
      .then((res) => {
        if (res.status === true) {
          setNotes(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  return (
    <div className="bg-gray-500 m-0 p-0" style={{ background: "#F9FAFF" }}>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-6 items-center justify-between py-2 bg-white fixed w-screen">
            <Link to="/dashboard">
              <div className="col-span-1 pl-4 py-1">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </Link>
            <div className="col-span-4 text-center bluetext text-lg py-1">
              <b>All Notes</b>
            </div>
            <div className="col-span-1 pl- text-center py-2"></div>
          </div>
          <div className="pt-12">
            {notes.length !== 0 ? (
              notes.map(({ id, topic }) => (
                <Link to={"/note/" + id}>
                  <div className="flex items-center justify-between p-4 my-2 bg-white">
                    <p className="text-sm">{topic}</p>
                    <i
                      class="fa fa-chevron-circle-right pr-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                </Link>
              ))
            ) : (
              <div className="grid grid-cols-1 px-10 pt-10 h-screen text-center text-sm">
                <p className="">
                  No available notes for the course right now, check back later
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(CourseView);
// export default Home;
