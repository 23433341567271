import React from "react";
import { Circles } from "react-loader-spinner";

const Spinner = () => {
  return (
    <div className="text-center">
      <div className="grid h-screen place-items-center py-60">
        <Circles
          height="80"
          width="80"
          color="#1437e9"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default Spinner;
