import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ServerLink, FileLink } from "../../inc/keys/Links";
import GetApi from "../../Api/GetApi";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../inc/Spinner";
import Quiz from "../Quiz/Quiz";

const DiseaseNote = () => {
  const [disease, setDisease] = useState({});
  const [currentPage, setCurrentPage] = useState("note");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDiseaseNote();
    getQuestions();
    //eslint-disable-next-line
  }, []);

  const { id } = useParams();

  const getDiseaseNote = () => {
    setLoading(true);
    GetApi(ServerLink + "/disease/" + id)
      .then((res) => {
        if (res.status === true) {
          setDisease(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const getQuestions = () => {
    setLoading(true);
    GetApi(ServerLink + "/questions")
      .then((res) => {
        if (res.status === true) {
          setQuestions(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  return (
    <div
      className="md:hidden bg-gray-500 m-0 p-0"
      style={{ background: "#F9FAFF" }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-6 items-center justify-between bg-white fixed w-screen">
            <Link to={"/system/" + disease.system_id}>
              <div className="col-span-1 pl-4 py-1">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </Link>
            <div className="col-span-4 text-center bluetext py-1">
              <b>{disease.topic}</b>
            </div>
            <div className="col-span-1 pl- text-center py-2">
              <i class="fa fa-heart-o" aria-hidden="true"></i>
            </div>
            <div
              className={
                currentPage === "note"
                  ? "col-span-3 text-center py-2 text-sm bluetext text-bold"
                  : "col-span-3 text-center py-2 text-sm"
              }
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setCurrentPage("note")}
            >
              Lecture Note
            </div>
            <div
              className={
                currentPage === "quiz"
                  ? "col-span-3 text-center py-2 text-sm bluetext text-bold"
                  : "col-span-3 text-center py-2 text-sm"
              }
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setCurrentPage("quiz")}
            >
              Quiz
            </div>
          </div>
          {currentPage === "note" ? (
            <div className="p-3 text-sm pt-24">
              <div className="mb-6">
                <p className="bluetext mb-2">
                  <b>Definition</b>
                </p>
                <p>{disease.definition}</p>
              </div>
              {disease.types ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Types</b>
                  </p>
                  <ul>
                    {disease.types.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm "
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
              {disease.causes ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Causes</b>
                  </p>
                  <ul>
                    {disease.causes.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm"
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div className="mb-6">
                <p className="bluetext mb-2">
                  <b>Pathopyhysiology</b>
                </p>
                <img
                  src={FileLink + "/diseasesx/" + disease.pathophysiology}
                  alt="pathophysiology"
                />
              </div>
              {disease.clin_manifest ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Clincal Manifestation</b>
                  </p>
                  <ul>
                    {disease.clin_manifest.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm"
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
              {disease.diagnostic_eval ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Diagnostic Evaluation</b>
                  </p>
                  <ul>
                    {disease.diagnostic_eval.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm"
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
              {disease.management ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Management</b>
                  </p>
                  <ul>
                    {disease.management.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm"
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
              {disease.nursing_diagnosis ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Nursing Diagnosis</b>
                  </p>
                  <ul>
                    {disease.nursing_diagnosis.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm"
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
              {disease.complications ? (
                <div className="mb-6">
                  <p className="bluetext mb-2">
                    <b>Complications</b>
                  </p>
                  <ul>
                    {disease.complications.split(";").map((item) => (
                      <span className="flex">
                        <i
                          class="fa fa-dot-circle-o pr-2 text-sm"
                          aria-hidden="true"
                        ></i>
                        <li className="">{item}</li>
                      </span>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : (
            <Quiz questions={questions} />
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(DiseaseNote);
