import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ServerLink } from "../../inc/keys/Links";
import GetApi from "../../Api/GetApi";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../inc/Spinner";
import Quiz from "../Quiz/Quiz";
// import parse from "html-react-parser";

const NoteView = () => {
  const [note, setNote] = useState({});
  const [currentPage, setCurrentPage] = useState("note");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNote();
    getQuestions();
    //eslint-disable-next-line
  }, []);

  const { id } = useParams();

  const getNote = () => {
    setLoading(true);
    GetApi(ServerLink + "/note/" + id)
      .then((res) => {
        if (res.status === true) {
          setNote(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const getQuestions = () => {
    setLoading(true);
    GetApi(ServerLink + "/questions")
      .then((res) => {
        if (res.status === true) {
          setQuestions(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const htmlDecode = (content) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.innerHTML;
    // return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  return (
    <div
      className="md:hidden bg-gray-500 m-0 p-0"
      style={{ background: "#F9FAFF" }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-6 items-center justify-between bg-white fixed w-screen">
            <Link to={"/course/" + note.course_id}>
              <div className="col-span-1 pl-4 py-1">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </Link>
            <div className="col-span-4 text-center bluetext py-1">
              <b>{note.topic}</b>
            </div>
            <div className="col-span-1 pl- text-center py-2">
              <i class="fa fa-heart-o" aria-hidden="true"></i>
            </div>
            <div
              className={
                currentPage === "note"
                  ? "col-span-3 text-center py-2 text-sm bluetext text-bold"
                  : "col-span-3 text-center py-2 text-sm"
              }
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setCurrentPage("note")}
            >
              Lecture Note
            </div>
            <div
              className={
                currentPage === "quiz"
                  ? "col-span-3 text-center py-2 text-sm bluetext text-bold"
                  : "col-span-3 text-center py-2 text-sm"
              }
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setCurrentPage("quiz")}
            >
              Quiz
            </div>
          </div>
          {currentPage === "note" ? (
            <div className="p-3 text-sm pt-24">
              {/* {note.notes ? parse(note.notes) : null} */}
              <div
                dangerouslySetInnerHTML={{ __html: htmlDecode(note.notes) }}
              />
              <div className="text-center py-2">------End of note------</div>
            </div>
          ) : (
            <Quiz questions={questions} />
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(NoteView);
