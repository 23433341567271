import React from "react";
import Router from "./components/inc/Routes";

function App() {
  return (
    <div className="">
      <Router />
    </div>
  );
}

export default App;
