import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthRoute from "../../utils/AuthRoute";
import Onboarding from "../Onboarding/Onboarding";
import SignUp from "../Authentication/SignUp";
import SignIn from "../Authentication/SignIn";
import VerifyEmail from "../Authentication/VerifyEmail";
import Welcome from "../Onboarding/Welcome";
import Home from "../Dashboard/Home";
import Systems from "../Dashboard/MedSurg/Systems";
import SystemView from "../Dashboard/MedSurg/SystemView";
import DiseaseNote from "../Dashboard/MedSurg/DiseaseNote";
import LatestNotes from "../Dashboard/MedSurg/LatestNotes";
import Courses from "../Dashboard/Courses/Courses";
import Profile from "../Dashboard/Profile/Profile";
import CourseView from "../Dashboard/OtherNotes/CourseView";
import NoteView from "../Dashboard/OtherNotes/NoteView";
import EditProfile from "../Dashboard/Profile/EditProfile";
import Security from "../Dashboard/Profile/Security";
import ChangePassword from "../Dashboard/Profile/ChangePassword";
import Settings from "../Dashboard/Profile/Settings";
import QuizIndex from "../Dashboard/Quiz/QuizIndex";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Onboarding />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/welcome" element={<AuthRoute />}>
          <Route exact path="/welcome" element={<Welcome />} />
        </Route>
        <Route exact path="/dashboard" element={<AuthRoute />}>
          <Route exact path="/dashboard" element={<Home />} />
        </Route>
        <Route exact path="/systems" element={<AuthRoute />}>
          <Route exact path="/systems" element={<Systems />} />
        </Route>
        <Route exact path="/system/:id" element={<AuthRoute />}>
          <Route exact path="/system/:id" element={<SystemView />} />
        </Route>
        <Route exact path="/course/:id" element={<AuthRoute />}>
          <Route exact path="/course/:id" element={<CourseView />} />
        </Route>
        <Route exact path="/note/:id" element={<AuthRoute />}>
          <Route exact path="/note/:id" element={<NoteView />} />
        </Route>
        <Route exact path="/disease/:id" element={<AuthRoute />}>
          <Route exact path="/disease/:id" element={<DiseaseNote />} />
        </Route>
        <Route exact path="/latest-notes" element={<AuthRoute />}>
          <Route exact path="/latest-notes" element={<LatestNotes />} />
        </Route>
        <Route exact path="/courses" element={<AuthRoute />}>
          <Route exact path="/courses" element={<Courses />} />
        </Route>
        <Route exact path="/quiz" element={<AuthRoute />}>
          <Route exact path="/quiz" element={<QuizIndex />} />
        </Route>
        <Route exact path="/profile" element={<AuthRoute />}>
          <Route exact path="/profile" element={<Profile />} />
        </Route>
        <Route exact path="/edit-profile" element={<AuthRoute />}>
          <Route exact path="/edit-profile" element={<EditProfile />} />
        </Route>
        <Route exact path="/security" element={<AuthRoute />}>
          <Route exact path="/security" element={<Security />} />
        </Route>
        <Route exact path="/change-my-password" element={<AuthRoute />}>
          <Route
            exact
            path="/change-my-password"
            element={<ChangePassword />}
          />
        </Route>
        <Route exact path="/settings" element={<AuthRoute />}>
          <Route exact path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
