import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import logoicon from "../../../assets/images/logos/logoicon.png";
import userProfile from "../../../assets/images/icons/user.png";
import Structure from "../Layout/Structure";
import GetApi from "../../Api/GetApi";
import { ServerLink, FileLink } from "../../inc/keys/Links";
import Swal from "sweetalert2";
import { deleteToken } from "../../../utils/Common";
import { useNavigate } from "react-router-dom";
import Spinner from "../../inc/Spinner";
import { Link } from "react-router-dom";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    setLoading(true);
    GetApi(ServerLink + "/auth/me")
      .then((res) => {
        if (res) {
          setUser(res);
          setLoading(false);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const logout = () => {
    deleteToken();
    navigate("/signin");
  };

  const page = (
    <div
      className="md:hidden bg-gray-500 m-0 p-0"
      style={{ background: "#F9FAFF", overflow: "hidden" }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="bg-white text-center w-screen -ml-6 fixed py-1">
            <b className="bluetext">Profile</b>
          </div>
          <div className="flex justify-around items-center h-50 w-50 rounded-full mr-2 pt-14 text-center">
            {user.profile_picture !== null ? (
              <img
                className="img-big-circle mx-auto"
                src={FileLink + "/picturex/" + user.profile_picture}
                alt="profile"
                width="100%"
                style={{ border: "3px var(--primary-color) solid" }}
              />
            ) : (
              <img
                className="img-big-circle mx-auto"
                src={userProfile}
                alt="profile"
              />
            )}
          </div>
          <div className="text-center mt-2 pb-3">
            <p className="bluetext">
              <b>{user.firstname + " " + user.lastname}</b>
            </p>
            <p className="text-sm">{user.email}</p>
          </div>
          <div className="py-2">
            <Link to={"/edit-profile"}>
              <div className="flex items-center pb-3">
                <i class="fa fa-user pr-3" aria-hidden="true"></i>
                <p className="text">Edit Profile</p>
              </div>
            </Link>
            <hr />
          </div>
          <div className="py-2">
            <Link to={"/security"}>
              <div className="flex items-center pb-3">
                <i class="fa fa-lock pr-3" aria-hidden="true"></i>
                <p className="text">Security</p>
              </div>
            </Link>
            <hr />
          </div>
          <div className="py-2">
            <Link to={"/settings"}>
              <div className="flex items-center pb-3">
                <i class="fa fa-cog pr-2" aria-hidden="true"></i>
                <p className="text">Settings</p>
              </div>
            </Link>
            <hr />
          </div>
          <div className="flex items-center justify-center pt-5">
            <button
              type="button"
              className="bg-primary text-white px-7 py-3 rounded-lg w-full"
              data-te-ripple-init
              onClick={logout}
            >
              <i class="fa fa-sign-out pr-3" aria-hidden="true"></i>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Structure page={page} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Profile);
