import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../../inc/Spinner";
import { Link } from "react-router-dom";

const EditProfile = () => {
  const [loading, setLoading] = useState(true);
  //   const [user, setUser] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  return (
    <div className="">
      <div
        className="bg-gray-500 m-0 p-0 h-screen"
        style={{ background: "#F9FAFF" }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="grid grid-cols-6 items-center justify-between bg-white fixed w-screen px-2 py-2">
              <Link to="/profile">
                <div className="col-span-1 pl-2 py-1">
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </div>
              </Link>
              <div className="col-span-4 text-center bluetext text-lg py-1">
                <b>Security</b>
              </div>
              <div className="col-span-1 pl- text-center py-1"></div>
            </div>
            <div className="pt-6">
              <div className="px-4 mt-10">
                <Link to={"/change-my-password"}>
                  <div className="grid grid-cols-12 items-center justify-between pb-3">
                    <i
                      class="fa fa-lock pr-3 flex col-span-1 justify-start"
                      aria-hidden="true"
                    ></i>
                    <p className="text flex justify-start col-span-10">
                      Change My Password
                    </p>
                    <i
                      class="fa fa-chevron-right flex justify-end col-span-1"
                      aria-hidden="true"
                    ></i>
                  </div>
                </Link>
                <hr />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(EditProfile);
