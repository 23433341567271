import React from "react";
import { Link, useLocation } from "react-router-dom";
import fullLogo from "../../../assets/images/logos/full-logo.png";
import logoicon from "../../../assets/images/logos/logoicon.png";
import { connect } from "react-redux";

const Structure = ({ page }) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div>
      <div className="hidden md:grid grid-cols-7">
        <div className="flex col-span-7 bg-white h-18 fixed w-screen justify-between px-2">
          <div>
            <Link to="/">
              <img
                src={fullLogo}
                alt="Logo"
                className="items-center p-5"
                width="150px"
              />
            </Link>
          </div>
          <div className="flex justify-around items-center px-4">
            <i className="fa fa-bell-o fa-lg mr-3"></i>
            <div className="h-10 w-10 rounded-full mr-2 mt-2 text-center">
              <Link to="/" className="text-white">
                <img
                  src={logoicon}
                  className="img-circle mx-auto"
                  alt="profile"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-start-1 col-end-2 bg-primary h-screen">
          <div className="pl-4">
            <div className="mt-40 ">
              <Link to="/dashboard">
                <p className="py-4 text-sm bluetext bg-white">
                  <b>
                    <i className="fa fa-graduation-cap 3x px-2"></i>
                    My Learning
                  </b>
                </p>
              </Link>
              <Link to="/dashboard">
                <p className="py-4 text-sm text-white">
                  <b>
                    <i className="fa fa-leanpub 3x px-2"></i>
                    Courses
                  </b>
                </p>
              </Link>
              <Link to="/dashboard">
                <p className="py-4 text-sm text-white">
                  <b>
                    <i className="fa fa-question-circle 3x px-2"></i>
                    Quiz
                  </b>
                </p>
              </Link>
              <Link to="/dashboard">
                <p className="py-4 text-sm text-white">
                  <b>
                    <i className="fa fa-user 3x px-2"></i>
                    Profile
                  </b>
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-end-8 col-span-6 bg-gray-50 pl-4"> {page}</div>
      </div>
      <div className="lg:hidden flex flex-col h-screen justify-between">
        <main class="mb-auto h-screen p-3" style={{ background: "#F9FAFF" }}>
          {page}
        </main>
        <div className="h-16 fixed bottom-0 bg-gray-50 w-screen pt-3">
          <div className="flex items-center justify-around">
            <Link to="/dashboard">
              <div
                className={
                  path === "/dashboard"
                    ? "text-center text-sm bluetext"
                    : "text-center text-sm"
                }
              >
                <i className="fa fa-graduation-cap 3x px-2"></i>
                <div>My Learning</div>
              </div>
            </Link>
            <Link to="/courses">
              <div
                className={
                  path === "/courses"
                    ? "text-center text-sm bluetext"
                    : "text-center text-sm"
                }
              >
                <i className="fa fa-leanpub 3x px-2"></i>
                <div>Courses</div>
              </div>
            </Link>
            <Link to="/quiz">
              <div
                className={
                  path === "/quiz"
                    ? "text-center text-sm bluetext"
                    : "text-center text-sm"
                }
              >
                <i className="fa fa-question-circle 3x px-2"></i>
                <div>Quiz</div>
              </div>
            </Link>
            <Link to="/profile">
              <div
                className={
                  path === "/profile"
                    ? "text-center text-sm bluetext"
                    : "text-center text-sm"
                }
              >
                <i className="fa fa-user 3x px-2"></i>
                <div>Profile</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Structure);
