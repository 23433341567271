import React from "react";
import slider1 from "../../assets/images/onboarding/slider1.png";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Onboarding = () => {
  return (
    <div>
      <div className="hidden md:grid grid-cols-6 gap-4 items-center justify-center text-center bg-primary h-screen">
        <div className="col-start-2 col-span-4">
          <Card className="overflow-hidden">
            <CardBody className="text-center">
              <h2 className="header-font text-3xl bluetext">
                From Lecture Room to the Epic of your Career
              </h2>
              <div className="px-7">
                <p>
                  A dedicated platform for sharing healthcare information, to
                  eliminate the risks of information mismanagement.
                </p>
              </div>
              <div className="px-4 pt-4">
                <img className="mx-auto" src={slider1} alt="slider1" />
                <hr />
                <p className="px-7 pt-7">
                  <b>
                    DigibridgeRN is currently availbale only Mobile screen.
                    Kidly switch to your phone to have full access to the
                    DigibridgeRN installable PWA
                  </b>
                </p>
              </div>
            </CardBody>
            <CardFooter className="grid grid-col-1 justify-center mb-2">
              <a
                href="https://digibridgern.com"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className="bg-primary text-white py-3 rounded-lg w-96"
                  data-te-ripple-init
                >
                  Learn More
                </button>
              </a>
            </CardFooter>
          </Card>
        </div>
      </div>
      <div className="md:hidden grid grid-cols-8 gap-4 items-center justify-center text-center h-screen">
        <div className="col-span-8">
          <div className="overflow-hidden mx-5">
            <div className="text-center">
              <h2 className="header-font text-3xl bluetext">
                From Lecture Room to the Epic of your Career
              </h2>
              <div className="mt-2">
                <p>
                  A dedicated platform for sharing healthcare information, to
                  eliminate the risks of information mismanagement.
                </p>
              </div>
              <div className="p-2">
                <img className="mx-auto" src={slider1} alt="slider1" />
                <hr />
              </div>
            </div>
          </div>
          <div className="pt-2">
            <Link to="/signup">
              <button
                type="button"
                className="bg-primary text-white px-7 py-3 rounded-lg mt-4 w-80"
                data-te-ripple-init
              >
                Get Started
              </button>
            </Link>
          </div>
          <div className="pt-2">
            <Link to="/signin">
              <button
                type="button"
                className="btn-sec px-7 py-3 rounded-lg w-80"
                data-te-ripple-init
              >
                Login
              </button>
            </Link>
          </div>
          {/* <p className="text-sm py-2">
            Already have an account?{" "}
            <Link to="/signin">
              <span className="bluetext text-sm">
                <b>Login</b>
              </span>
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
