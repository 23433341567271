import React, { useEffect, useState } from "react";
import Structure from "../Layout/Structure";
import { connect } from "react-redux";
import heart from "../../../assets/images/heart-img.png";
import medsurg from "../../../assets/images/icons/medsurg.png";
import { Link } from "react-router-dom";
import GetApi from "../../Api/GetApi";
import { ServerLink } from "../../inc/keys/Links";
import Swal from "sweetalert2";
import Spinner from "../../inc/Spinner";
import { icons } from "../../inc/Icons";

const Courses = () => {
  const [note, setNote] = useState([]);
  const [otherNote, setOtherNote] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLatestNotes();
    getOtherLatestNotes();
    getCourses();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getOtherLatestNotes = () => {
    setLoading(true);
    GetApi(ServerLink + "/others-latest-notes")
      .then((res) => {
        if (res.status === true) {
          setOtherNote(res.data);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const getLatestNotes = () => {
    setLoading(true);
    GetApi(ServerLink + "/latest-notes")
      .then((res) => {
        if (res.status === true) {
          setNote(res.data);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const getCourses = () => {
    setLoading(true);
    GetApi(ServerLink + "/courses")
      .then((res) => {
        if (res.status === true) {
          setCourses(res.data);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const page = (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="md:mt-24 mt-2">
          {note.length > 0 ? (
            <div className="grid md:hidden grid-cols-2 gap">
              <div className="flex col-span-2 items-center justify-between py-2">
                <p className="bluetext">
                  <b>Latest Additions</b>
                </p>
                <Link to="/latest-notes">
                  <p className="text-xs bluetext underline">See all</p>
                </Link>
              </div>
              <div className="pr-3">
                <img src={heart} alt="heart" />
                <div className="mt-2">
                  <p className="mb-0 pb-0 h-[45px] text-center">
                    {note[0].topic}
                  </p>
                  {/* <p className="bluetext text-sm py-0">MSN - Cardiovascular</p> */}
                </div>
                <Link to={"/disease/" + note[0].id}>
                  <button
                    type="button"
                    className="bg-primary text-white px-4 py-2 rounded-md mt-2 w-[100%]"
                    data-te-ripple-init
                  >
                    View Note
                  </button>
                </Link>
              </div>
              {otherNote.length !== 0 ? (
                <div className="pl-3">
                  <img src={heart} alt="heart" />
                  <div className="mt-2">
                    <p className="mb-0 pb-0 h-[45px] text-center">
                      {otherNote[0].topic}
                    </p>
                    {/* <p className="bluetext text-sm py-0">MSN - Cardiovascular</p> */}
                  </div>
                  <Link to={"/note/" + otherNote[0].id}>
                    <button
                      type="button"
                      className="bg-primary text-white px-4 py-2 rounded-md mt-2 w-[100%]"
                      data-te-ripple-init
                    >
                      View Note
                    </button>
                  </Link>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="mt-5">
            <p className="bluetext">
              <b>Course Category</b>
            </p>
          </div>
          <div className="bg-white -mx-3 px-3">
            <div className="grid md:hidden grid-cols-2 gap">
              <div className="flex col-span-2 items-center justify-between py-2">
                <div className="flex items-center">
                  <img
                    src={medsurg}
                    alt="course-icon"
                    //   width="50px"
                    className="pr-2"
                  />
                  <div>
                    <p className="pb-0 mb-0">
                      <strong>MedSurg Nursing</strong>
                    </p>
                    <p className="text-sm" style={{ color: "gray" }}>
                      11 Systems Available
                    </p>
                  </div>
                </div>
                <Link to="/systems">
                  <i
                    class="fa fa-chevron-circle-right pr-2"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
          </div>
          {courses.map(({ id, name, total }) => (
            <div className="bg-white -mx-3 px-3 mt-2">
              <div className="grid md:hidden grid-cols-2 gap">
                <div className="flex col-span-2 items-center justify-between py-2">
                  <div className="flex items-center">
                    {icons.map((icon) =>
                      icon.id === id ? (
                        <img
                          src={icon.icon}
                          alt="course-icon"
                          className="pr-2"
                        />
                      ) : null
                    )}
                    <div>
                      <p className="pb-0 mb-0">
                        <strong>{name}</strong>
                      </p>
                      <p className="text-sm" style={{ color: "gray" }}>
                        {total} Notes Available
                      </p>
                    </div>
                  </div>
                  <Link to={"/course/" + id}>
                    <i
                      class="fa fa-chevron-circle-right pr-2"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          <div className="mb-24 pb-24"></div>
        </div>
      )}
    </>
  );

  return (
    <div>
      <Structure page={page} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Courses);
