// import React from "react";
import cookie from "js-cookie";

let token = cookie.get("token");

async function PostApi(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },

    redirect: "follow",
    body: JSON.stringify(data),
  });

  return response.json();
}
export default PostApi;

export async function postApiMultipart(url = "", data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: data,
  });

  return response.json();
}
