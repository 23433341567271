import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import logoicon from "../../assets/images/logos/logoicon.png";
import fullLogo from "../../assets/images/logos/full-logo.png";
import VerificationInput from "react-verification-input";
import { ServerLink } from "../inc/keys/Links";
import GetApi from "../Api/PostApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { setAuthData } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import { setToken } from "../../utils/Common";

const VerifyEmail = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
  }, []);

  const Verify = (e) => {
    e.preventDefault();
    setLoading(true);

    GetApi(ServerLink + "/user/verify/" + code)
      .then((res) => {
        if (res.status === true) {
          setToken(res.token);
          dispatch(setAuthData(res.token, res.user));
          Swal.fire({
            icon: "Success",
            text: res.message,
            confirmButtonColor: "#1437E9"
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/welcome");
          }, 1000);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9"
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "Success",
          text: "Network error. Try again",
          confirmButtonColor: "#1437E9"
        });
      });
  };

  return (
    <div>
      <div className="hidden md:grid grid-cols-6 gap-4 items-center justify-center text-center bg-primary h-screen">
        <div className="col-start-2 col-span-4">
          <Card className="overflow-hidden">
            <form onSubmit={Verify}>
              <CardBody className="text-center">
                <img
                  className="mx-auto pb-4"
                  src={fullLogo}
                  alt="logo"
                  width="150px"
                />
                <h2 className="header-font text-3xl bluetext">
                  You are almost there!
                </h2>
                <div className="mt-2 pb-4">
                  <p>Input the verification code sent to your email</p>
                </div>
                <div className="flex items-center justify-center mt-4">
                  <VerificationInput
                    length={6}
                    placeholder=""
                    classNames={{
                      container: "container",
                      character: "character",
                      characterInactive: "character--inactive",
                      characterSelected: "character--selected"
                    }}
                    onChange={(code) => {
                      setCode(code);
                    }}
                  />
                </div>
              </CardBody>
              <CardFooter className="pt-2">
                <div className="mb-4">
                  {loading ? (
                    <button
                      type="button"
                      className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-80"
                      data-te-ripple-init
                      disabled
                    >
                      Verifying...
                    </button>
                  ) : code.length !== 6 ? (
                    <button
                      type="button"
                      className="bg-gray-400 text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed w-80"
                      data-te-ripple-init
                      disabled
                    >
                      Enter Verification Code
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 w-80"
                      data-te-ripple-init
                      // onClick={subscribe}
                    >
                      Submit
                    </button>
                  )}
                </div>
                <p className="text-sm">
                  <Link to="/signup">
                    <span className="bluetext text-sm">
                      <b>Resend verification code</b>
                    </span>
                  </Link>
                </p>
              </CardFooter>
            </form>
          </Card>
        </div>
      </div>
      <div className="md:hidden grid grid-cols-8 justify-center text-center h-screen">
        <div className="col-span-8">
          <div className="">
            <form onSubmit={Verify}>
              <div className="text-center">
                <img
                  className="mx-auto mt-4 mb-4"
                  src={logoicon}
                  alt="logo"
                  width="50px"
                />
                <h2 className="header-font text-3xl bluetext">
                  You are almost there!
                </h2>
                <div className="mt-2 pb-4">
                  <p>Input the verification code sent to your email</p>
                </div>
                <div className="flex items-center justify-center mt-4">
                  <VerificationInput
                    length={6}
                    placeholder=""
                    classNames={{
                      container: "container",
                      character: "character",
                      characterInactive: "character--inactive",
                      characterSelected: "character--selected"
                    }}
                    onChange={(code) => {
                      setCode(code);
                    }}
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className=" mb-4">
                  {loading ? (
                    <button
                      type="button"
                      className="bg-primary text-white py-3 px-4 rounded-lg mt-4 m-0 md:px-12 md:py-2 cursor-not-allowed w-80"
                      data-te-ripple-init
                      disabled
                    >
                      Verifying...
                    </button>
                  ) : code.length !== 6 ? (
                    <button
                      type="button"
                      className="bg-gray-400 text-white py-3 px-4 rounded-lg m-0 mt-4 md:px-12 md:py-2 cursor-not-allowed w-80"
                      data-te-ripple-init
                      disabled
                    >
                      Enter Verification Code
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-primary text-white px-7 py-3 rounded-lg mt-4 w-80"
                      data-te-ripple-init
                      // onClick={subscribe}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
