import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import heart from "../../../assets/images/heart-img.png";
import { ServerLink } from "../../inc/keys/Links";
import GetApi from "../../Api/GetApi";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Spinner from "../../inc/Spinner";

const LatestNotes = () => {
  const [latestNotes, setLatestNotes] = useState([]);
  const [otherNotes, setOtherNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLatestNotes();
    getOtherLatestNotes();
  }, []);

  const getLatestNotes = () => {
    setLoading(true);
    GetApi(ServerLink + "/latest-notes")
      .then((res) => {
        if (res.status === true) {
          setLatestNotes(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: res.message,
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  const getOtherLatestNotes = () => {
    setLoading(true);
    GetApi(ServerLink + "/others-latest-notes")
      .then((res) => {
        if (res.status === true) {
          setOtherNotes(res.data);
          setLoading(false);
        } else {
          Swal.fire({
            text: "Error fetching user",
            icon: "error",
            confirmButtonColor: "#1437E9",
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          text: "Network error: check your internet connection",
          icon: "error",
          confirmButtonColor: "#1437E9",
        });
        setLoading(false);
      });
  };

  return (
    <div className="bg-gray-500 m-0 p-0" style={{ background: "#F9FAFF" }}>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-6 items-center justify-between py-2 bg-white fixed w-screen top-0">
            <Link to="/dashboard">
              <div className="col-span-1 pl-4 py-1">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </Link>
            <div className="col-span-4 text-center bluetext text-lg py-1">
              <b>Latest Additions</b>
            </div>
            <div className="col-span-1 pl- text-center py-2"></div>
          </div>
          <div className="grid md:hidden grid-cols-2 m-2 pt-18 mt-14">
            {latestNotes.map(({ id, topic }) => (
              <div className="p-1 bg-white mb-3 mx-1">
                <img src={heart} alt="heart" />
                <div className="mt-2">
                  <p className="mb-0 pb-0 text-sm h-[35px] text-center">
                    {topic}
                  </p>
                  {/* <p className="bluetext text-sm py-0">Total Notes: {9}</p> */}
                </div>
                <Link to={"/disease/" + id}>
                  <button
                    type="button"
                    className="bg-primary text-white px-4 py-2 rounded-md mt-2 w-[100%]"
                    data-te-ripple-init
                  >
                    View Note
                  </button>
                </Link>
              </div>
            ))}
            {otherNotes.map(({ id, topic }) => (
              <div className="p-1 bg-white mb-3 mx-1">
                <img src={heart} alt="heart" />
                <div className="mt-2">
                  <p className="mb-0 pb-0 text-sm h-[35px] text-center">
                    {topic}
                  </p>
                  {/* <p className="bluetext text-sm py-0">Total Notes: {9}</p> */}
                </div>
                <Link to={"/note/" + id}>
                  <button
                    type="button"
                    className="bg-primary text-white px-4 py-2 rounded-md mt-2 w-[100%]"
                    data-te-ripple-init
                  >
                    View Note
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(LatestNotes);
